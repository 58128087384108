import React from 'react';
import './Prices.scss';

const Prices = ({t}) => {
	const prices = [
		{ id: 1, price_label: `${t('Prices.2')}`, price_cost: '$15.00 → $20.00' },
		{ id: 2, price_label: `${t('Prices.3')}`, price_cost: '$50.00+' },
		{ id: 3, price_label: `${t('Prices.4')}`, price_cost: '$10.00' },
		{ id: 4, price_label: `${t('Prices.5')}`, price_cost: '$35.00' }
	]

	return (
		<>
			<section id="services" className="prices">
				<h3>{t('Prices.1')}</h3>
				<ul className="prices_container">
					{prices.map(price => {
						return (<li key={price.id} className="single_price">
							<p className="price_label">{price.price_label}</p>
							<p className="price_cost">{price.price_cost}</p>
						</li>)
					})}
				</ul>
				<p className="fine_print">{t('Prices.7')}</p>
			</section>
		</>
	)
}

export default Prices
