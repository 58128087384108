import React from 'react';
import { animateScroll as scroll, Link } from 'react-scroll';
import StarLogo from '../../assets/SVG/stars_logo.svg';
import './Footer.scss';

const scrollToTop = () => {
	scroll.scrollToTop({ duration: 100 });
};

const Footer = ({t}) => {
	return (
		<>
			<section className="footer">
				<div className="shop_logo">
					<img onClick={scrollToTop} src={StarLogo} alt="" srcSet="" />
				</div>
				<div className="shop_information">
					<h2>Star's Hair Design</h2>

					<a className="map_link" href="https://goo.gl/maps/zrqkiKdWsRfmcvg59" rel="noreferrer" target="_blank">
						<p>11206 Atlantic Ave.</p>
						<p>Lynwood, CA 90221</p>
					</a>
				</div>
				<div className="shop_days">
					<h2>{t('Footer.1')}</h2>
					<p>{t('DOTW.1')}</p>
					<p>{t('DOTW.2')}</p>
					<p>{t('DOTW.3')}</p>
					<p>{t('DOTW.4')}</p>
					<p>{t('DOTW.5')}</p>
					<p>{t('DOTW.6')}</p>
					<p>{t('DOTW.7')}</p>
				</div>
				<div className="shop_hours">
					<h2>{t('Footer.2')}</h2>
					<p>9:00AM → 5:00PM</p>
					<p>9:00AM → 5:00PM</p>
					<p>9:00AM → 5:00PM</p>
					<p>9:00AM → 5:00PM</p>
					<p>9:00AM → 5:00PM</p>
					<p>9:00AM → 5:00PM</p>
					<p>10:00AM → 2:00PM</p>
				</div>
				<div className="links">
					<h2>{t('Footer.3')}</h2>
					<p><Link to="story" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.1')}</Link></p>
					<p><Link to="services" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.2')}</Link></p>
					<p><Link to="stylists" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.3')}</Link></p>
					<p><Link to="contact" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.4')}</Link></p>
					<p><Link to="hours" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.5')}</Link></p>
				</div>
				<div className="madeby">
					<p>Star’s Hair Design X Christian Bonilla</p>
					<p>2021</p>
				</div>
			</section>
		</>
	)
}

export default Footer
