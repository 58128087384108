import React from 'react';
import { Link } from 'react-scroll';
import Stylist from '../../assets/Images/stylist_image_1.png';
import Stylist2 from '../../assets/Images/stylist_image_2.png';
import Stylist3 from '../../assets/Images/stylist_image_3.png';
import './Stylists.scss';

const Stylists = ({t}) => {
	return (
		<>
			<section id="stylists" className="stylists">
				<h3>{t('Stylists.1')}</h3>
				<div className="stylist_container">
					<div className="single_stylist" id="stylist_1">
						<Link to="contact" spy={true} smooth={true} offset={-50} duration={100}>
							<img src={Stylist3} alt="" srcSet="" />
							<p>Olga (Mgr)</p>
						</Link>
					</div>
					<div className="single_stylist" id="stylist_2">
						<Link to="contact" spy={true} smooth={true}  offset={-50} duration={100}>
							<img src={Stylist2} alt="" srcSet="" />
							<p>Jamilette (Stylst)</p>
						</Link>
					</div>
					<div className="single_stylist" id="stylist_3">
						<Link to="contact" spy={true} smooth={true}  offset={-50} duration={100}>
							<img src={Stylist} alt="" srcSet="" />
							<p>Trini (Stylst)</p>
						</Link>
					</div>
				</div>
			</section>
		</>
	)
}

export default Stylists
