import React from 'react';
import { animateScroll as scroll, Link } from 'react-scroll';
import StarsLogo from '../../assets/SVG/stars_logo.svg';
import './Header.scss';

const scrollToTop = () => {
	scroll.scrollToTop({ duration: 100 });
};

const Header = ({ isDesktop, mobileNav, hamburgerEffect, t }) => {
	return (
		<>
			<header className="header">
				<nav className="navbar">
					<div className="left_nav">
						<img onClick={scrollToTop} src={StarsLogo} alt="" srcSet="" />
					</div>
					<div className="right_nav">
						{!isDesktop && <div><input class="menu-btn" type="checkbox" id="menu-btn" />
							<label class="menu-icon" for="menu-btn" onClick={hamburgerEffect}><span class="navicon"></span></label></div>}
						<ul className={mobileNav ? "mobile_nav_links" : "nav_links"}>
							<li><Link to="story" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.1')}</Link></li>
							<li><Link to="services" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.2')}</Link></li>
							<li><Link to="stylists" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.3')}</Link></li>
							<li><Link to="contact" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.4')}</Link></li>
							<li><Link to="hours" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.5')}</Link></li>
						</ul>
					</div>
				</nav>
			</header>
		</>
	)
}

export default Header
