import React from 'react';
import { Link } from 'react-scroll';
import './MobileNav.scss';

const MobileNav = ({t}) => {
	return (
		<>
			<section className="mobile_nav_menu">
				<ul className="mobile_nav_links">
					<li><Link to="story" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.1')}</Link></li>
					<li><Link to="services" activeClass="active" spy={true} smooth={true} offset={-150} duration={100}>{t('Header.2')}</Link></li>
					<li><Link to="stylists" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.3')}</Link></li>
					<li><Link to="contact" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.4')}</Link></li>
					<li><Link to="hours" activeClass="active" spy={true} smooth={true} offset={-50} duration={100}>{t('Header.5')}</Link></li>
				</ul>
			</section>
		</>
	)
}

export default MobileNav
