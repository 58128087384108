import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './App.scss';
import Contact from './components/Contact/Contact';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Hours from './components/Hours/Hours';
import Landing from './components/Landing/Landing';
// import Loading from './components/Loading/Loading';
import MobileNav from './components/MobileNav/MobileNav';
import Prices from './components/Prices/Prices';
import Story from './components/Story/Story';
import Stylists from './components/Stylists/Stylists';
import TranslateButtons from './components/TranslateButtons/TranslateButtons';

function App() {
  const [mobileNav, setMobileNav] = useState(false);
  const [isDesktop, setDesktop] = useState(window.innerWidth > 1000);
  const [isActive, setIsActive] = useState(0);
  // const [isLoading, setIsLoading] = useState(true);
  const { t, i18n } = useTranslation();

  const buttons = [
    {
      translation: 'EN',
      language: 'english'
    },
    {
      translation: 'SP',
      language: 'spanish'
    }
  ]

  const changeActive = (idx) => {
    setIsActive(idx);
  }

  const updateMedia = () => {
    setDesktop(window.innerWidth > 1000);
  };

  const hamburgerEffect = () => {
    setMobileNav(prev => !prev);
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  // const handleLoading = () => {
  //   setIsLoading(!isLoading);
  //   console.log('loaded')
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //     console.log("Hello.")
  //   }, 2000)
  // }, [])

  useEffect(() => {
    if (isActive === 0) {
      console.log("I speake the English.")
      i18n.changeLanguage('en');
    } else {
      console.log("I hablo the Espanol.")
      i18n.changeLanguage('sp');
    }
  }, [isActive, i18n])

  return (
    <div className="App">
      {/* {!isLoading ? <div></div> : <Loading />} */}
      {mobileNav && <MobileNav t={t} />}
      <Header t={t} isDesktop={isDesktop} mobileNav={mobileNav} hamburgerEffect={hamburgerEffect} />
      <TranslateButtons isActive={isActive} changeActive={changeActive} buttons={buttons} />
      <Landing />
      <Story t={t} />
      <Prices t={t} />
      <Stylists t={t} />
      <Contact t={t} isDesktop={isDesktop} />
      <Hours t={t} />
      <Footer t={t} />
    </div>
  );
}

export default App;
