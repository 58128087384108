import React from 'react';
import Flag from '../../assets/SVG/flag.svg';
import './Marker.scss';

const Marker = (props) => {
  const { color, name, id } = props;
  return (
    <div className="marker"
      style={{ backgroundColor: color, cursor: 'pointer' }}
      title={name}
    >
      <img src={Flag} alt="" srcSet="" />
    </div>
  );
};

export default Marker;