import React from 'react'
import './Landing.scss'

const Landing = () => {
	return (
		<>
			<section className="landing_text">
				<h2>Star's</h2>
				<h3>Hair Design</h3>
			</section>
		</>
	)
}

export default Landing
