import React from 'react';
import './TranslateButtons.scss';

const TranslateButtons = ({isActive, changeActive, buttons}) => {

	return (
		<>
			<div className="translatebuttons">
				{buttons.map((trans, idx) => {
					return (
						<button key={idx} className={isActive === idx ? trans.language + " active" : trans.language} onClick={() => changeActive(idx)}>{trans.translation}</button>
					)
				})}
			</div>
		</>
	)
}

export default TranslateButtons
