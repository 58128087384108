import React, { Suspense } from 'react';
import { hydrate, render } from "react-dom";
import App from './App.jsx';
import './i18n';
import './index.css';

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<React.StrictMode>
    <Suspense fallback={(<div>Loading...</div>)}>
      <App />
    </Suspense>
  </React.StrictMode>, rootElement);
} else {
  render(<React.StrictMode>
    <Suspense fallback={(<div>Loading...</div>)}>
      <App />
    </Suspense>
  </React.StrictMode>, rootElement);
}

// ReactDOM.render(
//   <React.StrictMode>
//     <Suspense fallback={(<div>Loading...</div>)}>
//       <App />
//     </Suspense>
//   </React.StrictMode>,
//   document.getElementById('root')
// );
