import React from 'react';
import './Contact.scss';

const Contact = ({isDesktop, t}) => {


	return (
		<>
			<section id="contact" className="contact">
				<h3>{t('Contact.1')}</h3>
				<a href="tel:310-635-2858">
					{isDesktop ? (<h2 className="desktop_contact">(310) <br /> 625.2858</h2>) : (<h2 className="mobile_contact">(310) <br /> 625. <br/> 2858</h2>)}
				</a>
			</section>
		</>
	)
}

export default Contact
