import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import StarsImage from '../../assets/Images/stars_hair_design.png';
import './Story.scss';

const useMousePosition = () => {
	const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

	useEffect(() => {
		const updateMousePosition = event => {
			setMousePosition({ x: event.clientX, y: event.clientY })
		}
		window.addEventListener("mousemove", updateMousePosition)

		return () => window.removeEventListener("mousemove", updateMousePosition)
	}, [])

	return (mousePosition);
}

const getDimensionObject = (node) => {
	const rect = node.getBoundingClientRect();

	return {
		width: rect.width,
		height: rect.height
	}
}

const useSize = () => {
	const [dimensions, setDimensions] = useState({})
	const [node, setNode] = useState(null)

	const ref = useCallback(node => {
		setNode(node);
	}, []);

	useLayoutEffect(() => {
		if (node) {
			const measure = () => setDimensions(getDimensionObject(node));
			measure();
			// console.log(dimensions)
		}
	}, [node])
	return [ref, dimensions]
}

const Story = ({t}) => {
	const [hovered, setHovered] = useState(false);
	const toggleHover = () => setHovered(!hovered);

	const { x, y } = useMousePosition();
	const xPos = hovered ? x : 0;
	const yPos = hovered ? y : 0;
	// console.log(x, y)

	const [ref, { width, height }] = useSize();
	// useEffect(() => {
	// 	console.log(`width: ${width}, height:${height}`)
	// 	console.log(`x: ${x}, y:${y}`)
	// }, [width, height, x, y])

	return (
		<>
			<section className="story" id="story">
			<div className="image_container">
					<img 
					ref={ref}
					style={{
						transform: `translate(${xPos - width / 2}px, ${yPos - height / 2}px)`
					}} alt="star's hair design shop"
						className={hovered ? "stars_shop is-active" : "stars_shop"} src={StarsImage}
					/>
				</div>
				<h3>{t('Story.1')}</h3>
				<p onMouseEnter={toggleHover} onMouseLeave={toggleHover}>{t('Story.2')}</p>
				{/* “Star’s Hair Design provides professional service, low rates, and high quality hair care in the city of Lynwood, CA. As lifelong devotees of cosmetology and barbering, you’d be hard-pressed to find more expert professionals in the area at better rates.” */}
			</section>
		</>
	)
}

export default Story
